import * as React from 'react'

import { format } from 'date-fns'
import getUnixTime from 'date-fns/getUnixTime'
import { Helmet } from 'react-helmet'

import { useSiteMetadata } from '../../hooks'

interface ISeo {
  language?: string
  title?: string
  description?: string
  template?: string
  pathname?: string
  canonical?: string
  image?: string
  imageAlt?: string
  twitterUser?: string
  facebookUser?: string
  datePublished?: string
  dateUpdated?: string
  audioSrc?: string
  isBlogPost?: boolean
  isAudioPost?: boolean
  isLanding?: boolean
  isSubLanding?: boolean
  author?: {
    name: string
    email: string
  }
  organization?: {
    name: string
    email: string
    logo: string
  }
}
interface ISeoProps {
  description: string
  title: string
  lang: string
  template: string
  image: string
  pathname: string
  imageAlt: string | null
  isSubLanding: boolean
  isLanding: boolean
  isBlogPost: boolean
  isAudioPost: boolean
  twitterUser: string
  facebookUser: string
  canonical?: string
  audioSrc?: string | null
  datePublished?: string
  dateUpdated?: string
  siteUrl: string
  continent?: string
  themeColor: string
  author: {
    name: string
    email: string
  }
  organization: {
    name: string
    email: string
    logo: string
  }
}

const SeoMeta = (props: ISeoProps) => {
  const {
    title,
    description,
    lang,
    template,
    image,
    imageAlt,
    pathname,
    twitterUser,
    facebookUser,
    canonical,
    audioSrc = null,
    datePublished,
    dateUpdated,
    siteUrl,
    continent,
    isLanding = false,
    isSubLanding = false,
    isAudioPost = false,
    isBlogPost = false,
    author,
    organization,
    themeColor,
  } = props

  const copyrightYear = new Date().getFullYear()

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: pathname,
    headline: description,
    inLanguage: lang,
    mainEntityOfPage: pathname,
    description: description,
    name: title,
    author: {
      '@type': 'Person',
      name: author.name,
      email: author.email,
    },
    copyrightHolder: {
      '@type': 'Person',
      name: author.name,
      email: author.email,
    },
    copyrightYear,
    creator: {
      '@type': 'Person',
      name: author.name,
      email: author.email,
    },
    publisher: {
      '@type': 'Organization',
      name: organization.name,
      email: organization.email,
    },
    datePublished: datePublished,
    image: {
      '@type': 'ImageObject',
      url: `${image}`,
    },
  }

  // Initial breadcrumb list
  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': `${siteUrl}`,
        name: 'Home',
      },
      position: 1,
    },
  ]

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement,
  }

  let schemaArticle = null

  if (isLanding) {
    itemListElement.push({
      '@type': 'ListItem',
      item: {
        '@id': pathname,
        name: title,
      },
      position: 2,
    })
  }

  if (isSubLanding) {
    itemListElement.push(
      {
        '@type': 'ListItem',
        item: {
          '@id': `${siteUrl}/episodes/`,
          name: 'All Episodes',
        },
        position: 2,
      },
      {
        '@type': 'ListItem',
        item: {
          '@id': pathname,
          name: title,
        },
        position: 3,
      }
    )
  }

  if (isBlogPost || isAudioPost) {
    schemaArticle = {
      '@context': 'http://schema.org',
      '@type': 'Article',
      author: {
        '@type': 'Person',
        name: author.name,
      },
      copyrightHolder: {
        '@type': 'Person',
        name: author.name,
      },
      copyrightYear,
      creator: {
        '@type': 'Person',
        name: author.name,
      },
      publisher: {
        '@type': 'Organization',
        url: siteUrl,
        name: organization.name,
        logo: {
          '@type': 'ImageObject',
          url: `${organization.logo}`,
        },
      },
      datePublished: datePublished,
      dateModified: dateUpdated,
      description: description,
      headline: title,
      inLanguage: lang,
      url: pathname,
      name: title,
      image: {
        '@type': 'ImageObject',
        url: image,
      },
      mainEntityOfPage: pathname,
    }

    itemListElement.push(
      {
        '@type': 'ListItem',
        item: {
          '@id': `${siteUrl}/episodes`,
          name: 'All Episodes',
        },
        position: 2,
      },
      {
        '@type': 'ListItem',
        item: {
          '@id': `${siteUrl}/episodes/${continent}`,
          name: `${continent
            .replace(/-/g, ' ')
            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())} Episodes`,
        },
        position: 3,
      },
      {
        '@type': 'ListItem',
        item: {
          '@id': pathname,
          name: title,
        },
        position: 4,
      }
    )
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={template}
    >
      <meta name="description" content={description} />
      {image && <meta name="image" content={image} />}

      {pathname && <meta property="og:url" content={pathname} />}
      {canonical && <link rel="canonical" href={canonical} />}

      <link
        rel="alternate"
        type="application/rss+xml"
        title="World Web Stories podcast"
        href="/rss-podcast.xml"
      />
      {themeColor && <meta name="theme-color" content={themeColor} />}

      {/* FACEBOOK */}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {pathname && <meta property="og:url" content={pathname} />}
      {!isBlogPost && !audioSrc && <meta property="og:type" content="website" />}
      {image && <meta property="og:image" content={image} />}
      {image && imageAlt && <meta property="og:image:alt" content={imageAlt} />}

      {/* FACEBOOK AUDIO */}
      {isAudioPost && audioSrc && <meta property="og:type" content="music.song" />}
      {isAudioPost && audioSrc && (
        <meta property="music:creator" content="https://worlwebstories/about-david-dias" />
      )}
      {isAudioPost && audioSrc && <meta property="og:audio" content={audioSrc} />}
      {isAudioPost && audioSrc && (
        <meta property="og:audio:type" content="audio/vnd.facebook.bridge" />
      )}

      {/* FACEBOOK BLOG POST */}
      {isBlogPost && <meta property="og:type" content="article" />}
      {isBlogPost && facebookUser && (
        <meta property="article:publisher" content={`https://www.facebook.com/${facebookUser}`} />
      )}
      {isBlogPost && isAudioPost && datePublished && (
        <meta
          property="article:published_time"
          content={`${getUnixTime(new Date(datePublished))}`}
        />
      )}

      {/* TWITTER */}
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {twitterUser && <meta name="twitter:creator" content={twitterUser} />}

      {/* TWITTER IMAGE */}
      {image && <meta name="twitter:image" content={image} />}
      {image && imageAlt && <meta name="twitter:image:alt" content={imageAlt} />}
      {image && !isAudioPost && <meta name="twitter:card" content="summary_large_image" />}

      {/* TWITTER AUDIO */}
      {isAudioPost && <meta name="twitter:card" content="player" />}
      {isAudioPost && pathname && <meta name="twitter:player" content={pathname} />}
      {isAudioPost && <meta name="twitter:player:width" content="480" />}
      {isAudioPost && <meta name="twitter:player:height" content="480" />}

      {/* JSON LD */}
      {!isBlogPost && !isAudioPost && (
        <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>
      )}

      {isBlogPost ||
        (isAudioPost && (
          <script type="application/ld+json">{JSON.stringify(schemaArticle)}</script>
        ))}

      {breadcrumb && <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>}
    </Helmet>
  )
}

export const Seo = ({
  language,
  title,
  description,
  template,
  pathname,
  image,
  imageAlt,
  twitterUser,
  facebookUser,
  canonical,
  datePublished,
  dateUpdated,
  audioSrc,
  isAudioPost,
  isBlogPost,
  isLanding,
  isSubLanding,
  author,
  organization,
}: ISeo) => {
  const site = useSiteMetadata()

  if (site.siteUrl === '' && typeof window !== 'undefined') {
    site.siteUrl = window.location.origin
  }

  const removeFirstSlash = /^\/|\/$/g
  const extractContinent = /^\/+[a-z]+\/|\/+[a-z]+/g

  const currentSlug = `${pathname.replace(removeFirstSlash, '')}${pathname === '/' ? '' : '/'}`
  const pathnameClean = `${site.siteUrl}/${currentSlug}`
  const continent = `${pathname.replace(extractContinent, '')}`

  const metaSiteUrl = site.siteUrl
  const metaLang = language || site.siteLanguage
  const metaTitle = title?.slice(0, 70) || site.siteTitle
  const metaDescription = description || site.siteDescription?.slice(0, 160)
  const metaTemplate = template || site.siteTitleTemplate
  const metaPathname = pathnameClean || site.siteUrl
  const metaCanonical = canonical || pathnameClean

  const metaImage = image || site.siteImage
  const metaImageAlt = imageAlt || null

  const metaTwitterUser = twitterUser || site.userTwitter
  const metaFacebookrUser = facebookUser || site.userFacebook

  const metaDatePublished = datePublished
    ? format(new Date(datePublished), 'yyyy-MM-dd')
    : new Date(Date.now()).toISOString()
  const metaDateUpdated = dateUpdated
    ? format(new Date(dateUpdated), 'yyyy-MM-dd')
    : metaDatePublished && format(new Date(metaDatePublished), 'yyyy-MM-dd')

  const metaAuthor = author || site.author
  const metaOrganization = organization || site.organization
  const metaThemeColor = site.themeColor || '#000'

  return (
    <SeoMeta
      title={metaTitle}
      description={metaDescription}
      lang={metaLang}
      template={metaTemplate}
      image={metaImage}
      imageAlt={metaImageAlt}
      pathname={metaPathname}
      canonical={metaCanonical}
      audioSrc={audioSrc}
      datePublished={metaDatePublished}
      dateUpdated={metaDateUpdated}
      siteUrl={metaSiteUrl}
      twitterUser={metaTwitterUser}
      facebookUser={metaFacebookrUser}
      author={metaAuthor}
      organization={metaOrganization}
      continent={continent}
      isAudioPost={isAudioPost}
      isBlogPost={isBlogPost}
      isLanding={isLanding}
      isSubLanding={isSubLanding}
      themeColor={metaThemeColor}
    />
  )
}
